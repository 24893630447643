html,
body,
#root {
  width: 100%;
  height: 100%;
  font-family: 'Roboto', sans-serif;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.gm-style-iw.gm-style-iw-c,
.gm-style-iw.gm-style-iw-c > .gm-style-iw-d {
  padding: 0;
  overflow: unset !important;
}

.gm-ui-hover-effect {
  top: 5px !important;
  right: 5px !important;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
  -moz-appearance: textfield;
}

/* for dev mode only */
.brd {
  border: 1px solid red;
}

.brd-1 {
  border: 1px solid green;
}

.brd-2 {
  border: 1px solid blue;
}
