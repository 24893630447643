.react-datepicker__header {
  background-color: transparent !important;
}

.react-datepicker {
  border: none !important;
}

.react-datepicker__day {
  font-size: 14px;
  width: 40px !important;
  height: 40px;
  line-height: 40px !important;
  font-family: 'Roboto', sans-serif;
}

.react-datepicker__day.react-datepicker__day--selected,
.react-datepicker__day.react-datepicker__day--in-range {
  background-color: #276ef1;
  border-radius: 50%;
  color: #fff;
}

.react-datepicker__day.react-datepicker__day--in-selecting-range,
.react-datepicker__day.react-datepicker__day--selecting-range-end {
  border-radius: 50% !important;
}

.react-datepicker__day.react-datepicker__day--selected:hover,
.react-datepicker__day.react-datepicker__day--in-range:hover {
  background-color: #276ef1;
  border-radius: 50%;
}

.react-datepicker__day-name {
  font-size: 14px !important;
  color: #808080 !important;
  width: 40px !important;
}

.react-datepicker__current-month {
  font-size: 12px;
  color: #808080;
}
