.ol-tooltip {
    position: relative;
    user-select: none;
    font-family: 'Roboto', sans-serif;
    padding: 6px 8px;
    font-size: 13px;
    font-weight: bold;
    line-height: 16px;
    color: #fff;
    border: 2px solid #0F6EA4;
    border-radius: 8px;
    background: #FF7009;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.ol-tooltip-static {
    background: #FF7009;
    color: #fff;
    border: 2px solid #0F6EA4;
}
